<template><br>

  <div>
    <Navigation yvisina=-200 @reload-parent="methodThatForcesUpdate">
      <template #atag>
        <a></a>
    
      </template>
      <template #content>
        
        <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Tennis</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;">Take the field for a single or double game: doing sports on vacation always pays off.<br><br>
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="this.$root.scrollToSection('rez')">Your Reservations</button>&nbsp;
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="redirectTo('https://res.cloudinary.com/htbnyuq1s/image/upload/v1721553659/lthxhp6jqmnwntodzgyu.pdf')">Price list</button></p>
               
                

            </div>
         </div>
      </div>
        

   </section>

        <div class="darken-image  image-container">
          
        
  <div class="text-overlay">



  </div>
  </div>


  
    
    

    <div class="container">
    <form style="padding:50px;" v-on:submit.prevent="getSport">
      <input type="date" class="form-control" name="date" :min="new Date().toISOString().substr(0, 10)"  v-model="form.datum" v-on:input="getSport"><br>
  
    </form>
  
  
    <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message">{{ $t('availabletimes') }}</div>
    
    
        <div class="row">
          <div  class="col-md-4 col-xl-3" v-if="datoteke && !datoteke.length">
                <div class="card bg-c-tenis order-card">
      
    
                    <div class="card-block">
                        <i class=" f-right" style="cursor:pointer;"></i>
                        <span>
              
                            <h2 class="text-right">{{ $t('x45') }}</h2>    
                          
                 
                        </span> 
    
                    </div>
                </div>
            </div>
            
    
    
            <div class="col-md-4 col-xl-3" style="padding:15px;" v-for="i in datoteke" :id="'termin'+i">
                <div class="card bg-c-tenis order-card" >
      
    
                    <div class="card-block">
                        <i class="fa fa-arrow-right f-right" @click='rezervirajpotvrda(i)' style="cursor:pointer;"></i>
                        <span>
              
                            <h2 class="text-right"><span v-if="!jebesplatno(i)" style="color:rgb(184, 255, 213);">13€</span> <span v-else style="color:rgb(184, 255, 213);">Free</span>&nbsp;&nbsp; {{i}}h-{{ i+1 }}h</h2>    
                          
                 
                        </span> 
    
                    </div>
                </div>
            </div>
            
        <section id="rez">
            <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" v-if="prijava">{{ $t('rezervacijesporta') }}</div>
          </section>
            <div class="col-md-4 col-xl-3" v-if="prijava && !rezervacije.length" style="padding-top:5px;">
                <div class="card bg-c-green order-card">
      
    
                    <div class="card-block">
                        <i class=" f-right" style="cursor:pointer;"></i>
                        <span>
              
                          <h2 class="text-right">{{ $t('nemarezerviranog') }}</h2>    
                          
                 
                        </span> 
    
                    </div>
                </div>
            </div>
    
            <div class="col-md-4 col-xl-3" v-for="i in rezervacije" :id="'rez'+i[2]" style="padding-top:5px;" >
                <div class="card bg-c-green order-card">
      
    
                    <div class="card-block">
                        <i class="fa fa-times-circle  f-left"  @click="cancelpotvrda(i[2], i[1], i[0])" v-if="moguceodgoditi(i[1], i[0])"  style="cursor:pointer;color:rgb(197, 135, 135);font-size:35px;padding-right: 20px;"></i>
                        <i class="fa fa-barcode f-right" style="font-size:50px;color: white" @click="otvoribarkodform(i[4])"> </i>
                
                        <span>
                        
                          
                        
              
                          <h2 class="text-right">{{ new Date(i[1]).toLocaleDateString('en-UK', {day: 'numeric', month: 'short'}) }}<br>    {{ i[0]}}h-{{ i[0]+1 }}h</h2>    
             
                          
                    
                        </span> 
          
    
                    </div>
                </div>
            </div>
         
    <div style="height:200px;"></div>
      </div>
    </div>
  
  
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
  
  <div class="footer-content">
    <div class="container">
      <div class="row">
  
        <div class="col-lg-12 col-md-12">
          <div class="footer-info">
            <h3>Mon Perin</h3>
            <p>
     
          <p>Mon Perin d.d. Trg La Musa 2 HR-52211 Bale, Croatia</p><br>
          <p>Oib: 06374155285</p><br>
          
        
              <strong>Reservations & Info:</strong> +385 52 824 338<br>
              <strong>Reception:</strong> +385 52 824 338<br>
              <strong>Email:</strong> info@monperin.hr<br>
            </p>
          </div>
        </div>
  
  
        
  
        <div class="col-lg-4 col-md-12 footer-newsletter">
  
  
  
  
  </div>
  
  
  
  
      </div>
    </div>
  </div>
  
  
  </footer><!-- End Footer -->
  
      </template>
  
    </Navigation >
  
    
    <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('cancelres') }}</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>

          </div>
          <div class="modal-body">
            <p>{{ $t('cancelres2') }}</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
          <button type="button" @click="cancel" class="btn btn-danger f-left modalbutton"  style="border:none;background-color: #dc3545;" :disabled="canceldisabled">Cancel</button>
   
 <div style="padding-top:120px;" class="prazanprostor"></div>

         

          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="barkodform" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('x41') }}</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('barkodform')" ></i>

          </div>
          <div class="modal-body">
         
            <svg id="barkod"></svg>
          </div>
 
        </div>
      </div>
    </div>
    
    <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('reservesport') }}</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')" ></i>
  
          </div>
          <div class="modal-body">
   
   
            <form>
         
            <div><p><span v-if="!jebesplatno(form.terminsat)">You can make the payment later to our personnel on site<br>{{ $t('x31') }}</span> <span v-else>Free <br>{{ $t('x31') }}</span></p>
           </div>
            
        
          </form>
  
          </div>
          <div class="modal-footer d-flex justify-content-center">

            <button @click="rezerviraj"  class="btn btn-info f-left modalbutton" data-dismiss="modal" :disabled="buttonDisabled" >Submit</button>
              <div style="padding-top:120px;" class="prazanprostor"></div>

         
  
          </div>
        </div>
      </div>
    </div>
  
  
  
    </div>
      </template>
      
   
      <script>
      import JsBarcode from 'jsbarcode';
      import axios from 'axios'
      import Navigation from '../components/Navigation.vue'
      export default {
        name: 'Info',
        components: {
          Navigation
        },
      
        data(){
          return{
            do1:10,
            do2:19,
            datoteke:[],
            canceldisabled:false,
            rezervacije:[],
            id:"",
            canceldatum:"",
            cancelsat:"",
            navBarKey:0,
            buttonDisabled: false,
            prijava:false,
            email:"",
            platform:"",
            form:{
              datum:new Date().toISOString().substr(0, 10),
              qnt:1,
              email:"",
              terminsat:""
  
            }
          }
        },
        mounted(){
          window.scrollTo(0, 0)
          this.platform = Capacitor.getPlatform()
          
          const preloader = document.querySelector('#preloader');
          this.prijava = this.$store.getters.isAuth;
          if(this.prijava){
            this.mojiTermini()
          }
  
          
          this.getSport()
          this.getRadnoVrijeme()
        
      
        },
      
        methods:{
          

          otvoribarkodform(uid){
         
            this.$root.otvoriform('barkodform')
            const barcodeOptions = {
              format: 'EAN8',
              displayValue: false,
              width: 5,
              height: 150,
              lineColor:"black",

            };
            
            var uidElement = document.getElementById("barkod");
            JsBarcode(uidElement, uid, barcodeOptions);
          },
          async getRadnoVrijeme(){
          await axios
              .get('/mpguest/radnovrijeme')
              .then(response => {
               
  
                this.do1 = response.data.dotenis1
                this.do2 = response.data.dotenis2
     
              
              })
              .catch(error => {
               if (error.response.status === 401) {
                  axios.defaults.headers.common["Authorization"] = ""
                  localStorage.removeItem("token")
                  localStorage.removeItem("username")
                  localStorage.removeItem("userid")
                  this.$store.commit('removeToken')
                //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
                this.$router.push('/bye/')
              }
              })
        },
  
          jebesplatno(i){
            if([7, 8, 9, 19, 20, 21, 22].includes(i)){
              return false
            }
            return true
          },
          async togglepolicy(tekst){
            $("#"+tekst).toggle();
          },
  
          async plati(){
          if(!document.getElementById('flexCheckDefault').checked){
            this.$root.toastnoti(this.$t('error'), "Confirm sales policy to proceed...", "greska")
            return;
          }
            $("#preloader").show();
            $('#rezervirajModal').modal('hide')
            await axios
            
            .get("placanje/stvorihash/sport/"+this.form.datum+"/"+this.form.terminsat)
            .then(response => {
              this.$root.toastnoti(this.$t('success'), "Redirecting...", "uspjeh")
              this.openPaymentForm(response.data.hash, response.data.cartid)
    
    
      
            })
            .catch(error => {
              this.$root.toastnoti(this.$t('error'), "There has been error with our Payment service, please try to contact us because app might not be working properly", "greska")
              $("#preloader").hide();
             
            })
          },
          openPaymentForm(signature, cartid) {
        const formURL = 'https://formtest.wspay.biz/authorization.aspx';
        const formParams = {
          ShopID: 'MPGUEST',
          ShoppingCartID: cartid,
          Version: '2.0',
          TotalAmount: '13,00',
          Signature: signature,
          ReturnURL: 'https://mpguest.com/uspjeh/'+this.platform +'/tenis/'+this.form.qnt,
          CancelURL: 'https://mpguest.com/cancel/'+this.platform,
          ReturnErrorURL: 'https://mpguest.com/error/'+this.platform,
        };
  
        const form = document.createElement('form');
        form.name = 'pay';
        form.method = 'POST';
        form.action = formURL;
  
        for (const paramName in formParams) {
          if (formParams.hasOwnProperty(paramName)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = paramName;
            input.value = formParams[paramName];
            form.appendChild(input);
          }
        }
        $("#preloader").hide();
        document.body.appendChild(form);
        form.submit();
     
        
        
        
      },
      redirectTo(url){
            
            window.location.href = url
      
        },
    
  
          methodThatForcesUpdate(key){
            
            this.mojiTermini()
  
            this.getSport()
      
        },
  
        async cancelpotvrda(id, datum, sat){
            this.id = id
            this.canceldatum = datum
            this.cancelsat = sat
       
            this.$root.otvoriform('deleteConfirmModal')
    
        },
  
  
         moguceodgoditi(datum, sat){
        
          const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
        const now = new Date();
        const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
  
        if(this.jebesplatno(sat)  && targetDate > now){
            return true
          }
  
        if (diffInHrs > 2 && targetDate > now) {
          console.log("trueee")
  
          return true
        } else {
          console.log("falsee")
          return false
        }
    
        },
        async rezervirajpotvrda(sat){
            this.form.terminsat = sat
  
            this.$root.otvoriform('rezervirajModal')
    
        },
        async cancel(){
          this.canceldisabled = true
  
              var apilink = '/mpguest/odgoditermin/tenis/'+this.id+'/0'
              this.canceldatum = this.canceldatum + " " + this.cancelsat+":00:00"
  
    
            await axios
            
              .get(apilink)
              .then(response => {
                this.$root.toastnoti(this.$t('success'), this.$t('odgodatermina'), "uspjeh")
                $("#rez"+this.id).remove();
          
                this.$root.zatvoriform('deleteConfirmModal')
                this.canceldisabled = false
      
        
              })
              .catch(error => {
                this.canceldisabled = false
                if(error.response.status==400){
              this.$root.toastnoti(this.$t('error'), error.response.data, "error")
              return;
            }
  
                this.$root.toastnoti(this.$t('error'), this.$t('greska'), "uspjeh")
               
              })
    
        },
        async rezerviraj(){
          this.buttonDisabled = true
          
        
            axios.post('/mpguest/rezervirajtermin2/', this.form)
          .then((res) => {
  
            this.$root.toastnoti(this.$t('success'), this.$t('reserved'), "uspjeh")
              this.rezervacije.push([this.form.terminsat, this.form.datum, res.data.id, res.data.uid])
              $('#rezervirajModal').modal('hide')
              this.otvoribarkodform(res.data.uid)
              $("#termin"+this.form.terminsat).remove();
              
              this.buttonDisabled = false;
                  
            })
          .catch((error) => {
            if(error.response.status==400){
              this.$root.toastnoti(this.$t('error'), error.response.data, "error")
              this.buttonDisabled = false;
              return;
            }
            this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
              this.buttonDisabled = false;
          })
  
       
    
        },
       
          async getSport() {
            this.datoteke = {}
                const inputDate = new Date(this.form.datum); // replace with your input date
      const now = new Date();
   
  
      const hasAlreadyPassed = inputDate < now &&
                              (inputDate.getFullYear() < now.getFullYear() ||
                              (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                              (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
  
  
  
          if (!this.form.datum) {
            this.$root.toastnoti(this.$t('error'), this.$t('unesitedatum'), "error")
            return false;
          }
            //$("#preloader").show();
      
            await axios
              .get('/mpguest/sporttermini/tenis/'+this.form.datum)
              .then(response => {
  
                console.log(response.data.poruka)
                if(response.data.poruka=="ne"){
        
                  this.$root.toastnoti(this.$t('error'), "Pick a date before " + response.data.limit, "error")
                  this.datoteke = []
                  return;
  
                }
                this.datoteke = response.data.termini
                $("#preloader").hide();
      
        
              })
              .catch(error => {
                console.log(error)
                this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")
              
               
              })
      
          },
          async mojiTermini() {
            await axios
              .get('/mpguest/mojitermini/tenis/')
              .then(response => {
                this.rezervacije = response.data.termini
                $("#preloader").hide();
      
        
              })
              .catch(error => {
                //this.$root.toastnoti('Log in', "You are not logged in, but you can still", "greska")
                $("#preloader").hide();
               
              })
      
          },
        }
      }
      </script>
    
    
    <style>
    
    .videoloading{
      clip-path: inset(1px 1px);
      width:25%;
   
    }
    @media (max-width: 1279px) {
      .videoloading{
      width:100%;
   
    }
  }
   
    .btn-getstarted {
        font-size: 16px;
        color: var(--color-white);
        background: var(--color-primary);
        padding: 8px 23px;
        border-radius: 4px;
        transition: 0.3s;
        font-family: var(--font-secondary);
        border:none;
  
    
    
    }
  
    
    .order-card {
        color: #fff;
    }
    
    .bg-c-blue {
        background: linear-gradient(45deg, #73b4ff, #4099ff);
    }
    
    .bg-c-green {
    
        background: linear-gradient(45deg, rgba(46, 216, 182, 0.7), rgba(89, 224, 197, 0.7));
        
    }
    
    .bg-c-yellow {
        background: linear-gradient(45deg,#FFB64D,#ffcb80);
    }
    
    .bg-c-pink {
        background: linear-gradient(45deg,#FF5370,#ff869a);
    }
    
    
    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
        box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
        border: none;
        margin-bottom: 30px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    .card .card-block {
        padding: 25px;
    }
    
    .order-card i {
        font-size: 26px;
    }
    
    .f-left {
        float: left;
    }
    
    .f-right {
        float: right;
    }
    
    
    .bg-c-tenis {
        background: linear-gradient(45deg,#0ea2bd,#0ea2bd);
    }
    </style>
    
    <style scoped>
      
    .hero-animated {
        padding: 50px 0 0px; 
        min-height: 30vh;
    }
    
    section {
        padding: 0px 0; 
    
    }
  
    .hero-animated p {
      color: rgba(var(--color-secondary-rgb),.8);
      margin: 0 0 30px 0;
      font-size: 20px;
      font-weight: 400;
  }
  
  @media (max-width: 640px){
    .hero-animated p {
      font-size: 16px;
      margin-bottom: 30px;
  }
  }
  
    
    </style>
    
    <style scoped>
    .btn-outline-light:hover {

    background-color: transparent;
    border-color: #f8f9fa;
}

    .oasisnaslov{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    
    .sub-banner-sec {
  background: url(../assets/sport.jpg) no-repeat center;
  
  background-size: cover;
  height: 561px;


}

    .hero-animated{
      min-height: 15vh;
      padding: 0px
    }
    </style>